export const getMonthsFilters = () => {
  const startYear = 2020;
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();

  const months: string[] = [];

  for (let year = startYear; year <= currentYear; year += 1) {
    const startMonth = year === startYear ? 0 : 0;
    const endMonth = year === currentYear ? currentMonth : 11;

    for (let month = startMonth; month <= endMonth; month += 1) {
      months.push(`${year}-${String(month + 1).padStart(2, '0')}`);
    }
  }

  return months;
};
