import React, { FC } from 'react';
import { BooleanField, Datagrid, DateField, EditButton, List, ListProps, NumberField, TextField } from 'react-admin';
import UrlField from '../../components/UrlField';
import { RetailerFilters } from './RetailerFilters';
import { getMonthsFilters } from '../../helpers/get-months-filter';

export const RetailerList: FC<ListProps> = (props) => {
  const monthsChoices = getMonthsFilters().map((month) => ({ id: month, name: month }));
  const lastMonth = monthsChoices[monthsChoices.length - 1].id;
  return (
    <List
      bulkActionButtons={false}
      filters={<RetailerFilters datechoices={monthsChoices} />}
      filterDefaultValues={{ date: lastMonth }}
      {...props}
    >
      <Datagrid>
        <UrlField source="name" linkSource="shop" />
        <TextField label="Orders" source="ordersAmount" />
        <TextField label="Products" source="productsAmount" />
        <DateField label="Last order date" source="lastOrderDate" />
        <NumberField source="revenue" label="Revenue" options={{ style: 'currency', currency: 'USD' }} />
        <NumberField source="profit" label="Profit" options={{ style: 'currency', currency: 'USD' }} />
        <NumberField source="fee" label="Total Fees Charged" options={{ style: 'currency', currency: 'USD' }} />
        <TextField source="currentPlan" />
        <DateField label="Plan renewal date" source="planRenewalDate" />
        <DateField label="Registration date" source="planRegistrationDate" />
        <BooleanField source="isLegacyPaidVisible" />
        <BooleanField source="isLegacyFreeVisible" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
