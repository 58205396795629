import { FC } from 'react';
import { BooleanInput, RadioButtonGroupInput, Edit, EditProps, SimpleForm, TextInput } from 'react-admin';

export const SupplierEdit: FC<EditProps> = (props) => {
  return (
    <Edit title="Edit a supplier" mutationMode="pessimistic" {...props}>
      <SimpleForm redirect="list">
        <TextInput source="name" disabled />
        <RadioButtonGroupInput
          source="marketplaceApprovalStatus"
          choices={[
            { id: 'approved', name: 'Approved' },
            { id: 'pending', name: 'Pending' },
            { id: 'not issued', name: 'Not issued' },
          ]}
        />
        <BooleanInput source="hidden" label="Hide" />
        <TextInput source="transactionFee" label="Transaction Fee %" />
        <BooleanInput label="Featured Supplier" source="isFeaturedSupplier" />
      </SimpleForm>
    </Edit>
  );
};
