import React, { FC } from 'react';
import { Filter, FilterProps, ReferenceInput, SelectInput } from 'react-admin';

export const RetailerFilters: FC<Omit<FilterProps, 'children'> & { datechoices: { id: string; name: string }[] }> = ({
  datechoices,
  ...props
}) => (
  <Filter {...props}>
    <ReferenceInput source="plan" reference="plans" alwaysOn sort={{ field: 'name', order: 'ASC' }}>
      <SelectInput optionText="displayName" />
    </ReferenceInput>
    <SelectInput
      allowEmpty={false}
      source="date"
      choices={datechoices.filter((choice) => choice.id && choice.name)}
      optionText="name"
      alwaysOn
    />
  </Filter>
);
